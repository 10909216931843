@import "./normilize.scss";
@import "./constants.scss";
@import "./fonts.scss";
@import "./buttons.scss";
@import "./inputs.scss";
@import "./loading.scss";
@import "./table.scss";
@import "./sidebar.scss";
@import "./typography.scss";

.nav-subpages {
  display: flex;
  flex-wrap: wrap;
}
.nav-subpage {
  &.active-page {
    border-radius: 4px 4px 0 0;
    background-color: $white;
    box-shadow: 0 -4px 5px 0px rgba(0, 0, 0, 0.1);
    a,
    button {
      opacity: 1;
    }
  }
  a,
  button {
    opacity: 0.6;
    display: block;
    color: $blueDarkText;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    background: none;
    padding: 20px 30px;
    &:hover {
      opacity: 1;
    }
  }

  &.with-loading {
    position: relative;
    &.loading-blue {
      &::after {
        width: 75px;
        height: 75px;
        background-size: contain;
      }
    }
  }
}
.content-page {
  border-radius: 0 4px 4px 4px;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.type-radio-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  .radio-item {
    margin-bottom: 20px;
    margin-right: 25px;
  }
  label {
    cursor: pointer;
    position: relative;
  }
  i {
    position: absolute;
    top: -2px;
    right: 0;
    bottom: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border: 2px solid $blueDarkText;
      box-sizing: border-box;
      border-radius: 100%;
      opacity: 0.6;
    }
  }
  input:checked {
    & + i {
      &:before {
        opacity: 1;
      }
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $blueDarkText;
        border-radius: 100%;
        top: 4px;
        left: 4px;
      }
      & + span {
        opacity: 1;
      }
    }
  }
  .radio-text {
    opacity: 0.6;
    color: $blueDarkText;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 30px;
  }
}
.mt_30 {
  margin-top: 30px;
}
.line_hor {
  margin: 16px 0;
  width: 100%;
  background-color: rgba($grey, 0.2);
  height: 1px;
}
