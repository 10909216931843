@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd/CircularStd-Book.eot");
  src: url("../fonts/CircularStd/CircularStd-Book.woff") format("woff");
  src: url("../fonts/CircularStd/CircularStd-Book.woff2") format("woff2");
  src: url("../fonts/CircularStd/CircularStd-Book.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd/CircularStd-Medium.eot");
  src: url("../fonts/CircularStd/CircularStd-Medium.woff") format("woff");
  src: url("../fonts/CircularStd/CircularStd-Medium.woff2") format("woff2");
  src: url("../fonts/CircularStd/CircularStd-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Averta Std ExtraBold";
  src: url("../fonts/AvertaStd/AvertaStd-ExtraBold.eot");
  src: url("../fonts/AvertaStd/AvertaStd-ExtraBold.woff") format("woff");
  src: url("../fonts/AvertaStd/AvertaStd-ExtraBold.woff2") format("woff2");
  src: url("../fonts/AvertaStd/AvertaStd-ExtraBold.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Averta Std Semibold";
  src: url("../fonts/AvertaStd/AvertaStd-Semibold.eot");
  src: url("../fonts/AvertaStd/AvertaStd-Semibold.woff") format("woff");
  src: url("../fonts/AvertaStd/AvertaStd-Semibold.woff2") format("woff2");
  src: url("../fonts/AvertaStd/AvertaStd-Semibold.ttf") format("truetype");
  font-weight: 500;
}

html {
  font-family: "Circular Std";
  font-weight: 300;
}
input {
  font-family: "Circular Std";
  font-weight: 300;
}
