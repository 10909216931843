.page-title {
  color: $blueDarkText;
  font-size: 32px;
  font-weight: 300;
  line-height: 41px;
  margin-bottom: 25px;
}

.page-subtitle {
  color: rgba(0, 13, 70, 0.6);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.form-error-message {
  color: red;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
}
