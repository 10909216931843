.loading-white {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(26, 37, 89, 0.2);
    z-index: 10;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
    margin: auto;
    background-image: url("../img/loading-white.svg");
    z-index: 11;
  }
  .form-innerwrap,
  .loading-innerwrap {
    filter: blur(2px);
  }
}

.loading-blue {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(26, 37, 89, 0.2);
    z-index: 10;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
    margin: auto;
    background-image: url("../img/loading-blue.svg");
    z-index: 11;
  }
  .form-innerwrap,
  .loading-innerwrap {
    filter: blur(2px);
  }
}
