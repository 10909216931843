.top-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  div.search-wrap {
    width: 320px;
    max-width: 100%;
    margin-left: 20px;

    input {
      font-size: 16px;
      padding: 10px 16px 10px 46px;
    }
  }
}

.table-wrap {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  &.loading-blue {
    min-height: 300px;
  }
}
.table {
  .rdt_TableHead {
    border: none;
    .rdt_TableCol {
      color: $blueDarkText;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
    }
  }
  div.rdt_TableRow {
    border: none;
    padding: 15px 0;
    &:nth-child(2n + 1) {
      background-color: $blueLightBackground;
    }
    .photo {
      line-height: 0;
      img {
        height: 34px;
        width: 34px;
        border-radius: 2px;
        object-fit: cover;
      }
    }
    .action {
      display: flex;
      align-items: center;
      .edit {
        display: block;
        line-height: 0;
        height: 24px;
        width: 24px;
        opacity: 0.6;
        margin-right: 25px;
        background: none;
        &:hover {
          opacity: 1;
        }
      }
      .delete {
        display: block;
        line-height: 0;
        height: 24px;
        width: 24px;
        opacity: 0.6;
        background: none;
        padding: 0;
        &:hover {
          opacity: 1;
        }
      }
      .info {
        display: block;
        line-height: 0;
        height: 24px;
        width: 24px;
        margin-right: 25px;
        background: none;
        cursor: pointer;
        position: relative;
        & > span {
          z-index: -1;
          opacity: 0;
          background-color: $blueDarkText;
          position: absolute;
          font-size: 14px;
          width: 96px;
          color: $white;
          padding-left: 10px;
          padding-right: 10px;
          text-align: center;
          border-radius: 4px;
          padding-top: 5px;
          padding-bottom: 5px;
          transition: all 0.3s ease;
          line-height: 20px;
          top: -55px;
          left: -35px;
          box-sizing: border-box;
        }
        svg {
          opacity: 0.6;
          &:hover {
            opacity: 1;

            & + span {
              z-index: 2;
              opacity: 1;
            }
          }
          path:not([fill="none"]) {
            fill: rgb(0, 13, 70);
          }
        }
      }
    }
    .rdt_TableCell {
      color: $blueDarkText;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      & > div.status {
        color: #ffc107;
        &_Sent {
          color: #2ecc71;
        }
      }
    }
  }

  .rdt_TableCell,
  .rdt_TableCol {
    .checkbox {
      input {
        height: 20px;
        width: 20px;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        border-radius: 4px;
        &:checked + .checkbox-view {
          &:before {
            content: "";
            background-image: url("../img/checked-blue.svg");
            width: 20px;
            height: 20px;
            position: relative;
            top: -2px;
            left: -2px;
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: initial;
          }
        }
        & + .checkbox-view {
          height: 20px;
          width: 20px;
          display: inline-block;
          line-height: 0;
          background-color: $white;
          border: 2px solid $blueDarkText;
          box-sizing: border-box;
          cursor: pointer;
          border-radius: 4px;
        }
      }
    }
  }
}

.paginate-prepage {
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 30px;
}
.paginate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .prev-page,
  .next-page {
    height: 32px;
    width: 32px;
    border: 1px solid rgba(47, 54, 64, 0.2);
    border-radius: 3px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    &:hover:not([disabled]) {
      box-shadow: rgba(47, 54, 64, 0.25) 0px 0px 10px 0px;
      border: 1px solid $grey;
    }
    &:disabled {
      opacity: 0.5;
      background: rgba(0, 0, 0, 0.1);
      cursor: initial;
    }
  }
  .text {
    color: $blueDarkText;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
}
.perpage {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .text {
    margin-right: 12px;
    color: $blueDarkText;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
  .select {
    margin-bottom: 0;
    width: 90px;
    & > input,
    & > ul {
      color: $blueDarkText;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
    & > input {
      padding: 8px 30px 7px 10px;
    }
    & > ul {
      & > button {
        padding: 6px 10px;
      }
    }
  }
}

.nothing-found {
  padding: 50px 15px;
  text-align: center;
  color: $blueDarkText;
  font-size: 21px;
  font-weight: 300;
  line-height: 20px;
}
