.btn-blue {
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(
    0deg,
    $gradientFreeCardFrom -16.46%,
    $gradientFreeCardTo 98.11%
  );
  border: 1px solid $blue;
  color: $white;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  padding: 10px;
  transition: 0.15s all ease;
  &:hover {
    box-shadow: $blue 0px 0px 10px 0px;
  }
  &:disabled,&[disabled] {
    opacity: 0.5;
    cursor: auto;
    box-shadow: none;
  }
}

.btn-blue-outline {
  width: 100%;
  border-radius: 4px;
  background-color: $white;
  color: $blue;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  border: 1px solid $blue;
  padding: 10px;
  transition: 0.15s all ease;
  &:hover {
    box-shadow: $blue 0px 0px 10px 0px;
  }
}

.blue-link {
  color: $blue !important;
  transition: 0.15s all ease;
  &:hover {
    text-decoration: underline;
  }
}

.btn-blue-dashed {
  border-radius: 4px;
  background-color: $white;
  color: $blue;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  padding: 10px 15px;
  border: 1px dashed $blue;
  transition: 0.15s all ease;
  &:hover {
    box-shadow: $blue 0px 0px 5px 0px;
  }
  &:disabled {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.15);
    cursor: initial;
    &:hover {
      box-shadow: none;
    }
  }
}

.btn-white {
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  color: $blue;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  padding: 10px 20px;
  transition: 0.15s all ease;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 0px;
  }
  &:disabled {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    cursor: initial;
    background-color: #e6e6e6;
    color: rgba(81, 92, 221, 0.7);
  }
}

.white-btn-link {
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: block;
  text-align: center;
  padding: 10px 20px;
  color: $blue;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 0px;
  }
}

.btn-green {
  border-radius: 4px;
  background-color: #008000;
  color: $white;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  padding: 10px 30px;
  transition: 0.15s all ease;
  &:hover {
    box-shadow: #008000 0px 0px 10px 0px;
  }
}

.btn-red {
  border-radius: 4px;
  background-color: #ff0000;
  color: $white;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  padding: 10px 30px;
  transition: 0.15s all ease;
  &:hover {
    box-shadow: #ff0000 0px 0px 10px 0px;
  }
}
