.phone-wrap {
  position: relative;
  .phone_input_label {
    color: #000D46;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .input-phone {
    input {
      width: 100% !important;
      border: none !important;
      padding: 10px 16px 11px 55px !important;
      height: auto !important;
      font-size: 18px !important;
      line-height: 20px !important;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    }
    .flag-dropdown {
      border: none;
      background: #4e5889 !important;
      border: none !important;
      &.open {
        .selected-flag {
          background: #4e5889 !important;
        }
      }
    }
    .selected-flag {
      width: 45px !important;
      &:hover,
      &:focus {
        background-color: #4e5889 !important;
      }
      .arrow {
        border-top: 4px solid #95989d !important;
      }
    }
  }
  .phone_label {
    color: #000D46;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  &-white {
    .label {
      display: block;
      font-family: "Circular Std";
      color: $blueDarkText;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      position: inherit;
      padding: 0;
      left: 0;
      top: 0;
      background: none;
      margin-bottom: 10px;
    }
    .input-phone {
      input {
        border-radius: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        line-height: 20px;
        padding: 13px 16px 13px 55px !important;
        &:disabled {
          cursor: initial;
        }
      }
      .flag-dropdown {
        background: none !important;
        &.open {
          .selected-flag {
            background: none !important;
          }
        }
      }
      .selected-flag {
        width: 45px !important;
        &:hover,
        &:focus {
          background: none !important;
        }
        .arrow {
          border-top: 4px solid #95989d !important;
          &.up {
            border-top: none !important;
            border-bottom: 4px solid #95989d;
          }
        }
      }
    }

    .lock-icon-wrap {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

.blue-checkbox {
  display: flex;
  align-items: center;
  .checkbox-input-wrap {
    display: inline-block;
    line-height: 0;
    position: relative;
    margin-right: 10px;
    input {
      height: 20px;
      width: 20px;
      opacity: 0;
      cursor: pointer;
      position: absolute;
      border-radius: 4px;
      &:checked + .checkbox-view {
        &:before {
          content: "";
          background-image: url("../img/checked.svg");
          width: 20px;
          height: 20px;
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: initial;
        }
      }
      & + .checkbox-view {
        height: 20px;
        width: 20px;
        display: inline-block;
        line-height: 0;
        background: linear-gradient(
          0deg,
          $gradientFreeCardFrom -16.46%,
          $gradientFreeCardTo 98.11%
        );
        cursor: pointer;
        border-radius: 4px;
      }
    }
  }
}

.white-checkbox {
  display: flex;
  align-items: center;
  .checkbox-input-wrap {
    display: inline-block;
    line-height: 0;
    position: relative;
    margin-right: 8px;
    input {
      height: 20px;
      width: 20px;
      opacity: 0;
      cursor: pointer;
      position: absolute;
      border-radius: 4px;
      &:checked + .checkbox-view {
        &:before {
          content: "";
          background-image: url("../img/checked.svg");
          width: 20px;
          height: 20px;
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: initial;
        }
      }
      & + .checkbox-view {
        height: 20px;
        width: 20px;
        display: inline-block;
        line-height: 0;
        background-color: $blue;
        cursor: pointer;
        border-radius: 4px;
      }
    }
  }
}

.check_input:checked + .check_box_darkBlue {
  background-image: url("../img/checked_darkBlue.svg");
}
