.sidebar-overlay-wrap {
  .sidebar {
    min-width: 40rem;
    max-width: 90vw;
    height: 100vh;
    background-color: $white;
    position: fixed;
    left: auto;
    right: 0;
    top: 0;
    z-index: 101;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease;
    transform: translateX(100%);
    padding: 30px;
    box-sizing: border-box;

    &.show {
      transform: translateX(0%);
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      box-sizing: border-box;
      margin-bottom: 10px;
      .title-wrap {
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 0;
        color: $blueDarkText;
      }
      .products {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 0;
        color: #000000;
      }

      .close-wrap {
      }
      button.close {
        background: none;
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
    }

    .main-content {
      height: calc(100vh - 130px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: $white;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: $blueText;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: $blueLight;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: $blueText;
        }
      }
    }
  }

  .sidebar-overlay {
    font-size: 0;
    background: rgba(69, 83, 99, 0.82);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
    z-index: 100;
    transition: all 0.3s ease;

    &.show {
      opacity: 1;
      display: block;
    }
  }
}
