$white: #ffffff;
$blueDark: #101c52;
$blueDarkBlour: #1a2559;
$blue: #515cdd;
$blueLight: #dcdef8;
$blueText: #737ce4;
$blueDarkText: #000d46;
$blueLightBackground: #f7f8fc;
$grey: #2f3640;
$gradientFreeCardFrom: #515CDD;
$gradientFreeCardTo: #3FB1FF;
